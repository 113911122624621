import classNames from 'classnames';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';

import querystring from 'query-string'; //引入querystring
import backIcon from '../../lib/assets/icon--back.svg'; //引入backIcon

import dataURItoBlob from '../../lib/data-uri-to-blob'; // 引入dataRUItoBlob

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import SaveStatus from './save-status.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx';
import LoginDropdown from './login-dropdown.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';

import {openTipsLibrary} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png';
import feedbackIcon from './icon--feedback.svg';
import profileIcon from './icon--profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';

import scratchLogo from './scratch-logo.svg';

const cookies = require('browser-cookies'); //引入browser-cookies

const messages = defineMessages({
    confirmNav: {
        id: 'gui.menuBar.confirmNewWithoutSaving',
        defaultMessage: 'Replace contents of the current project?',
        description: 'message for prompting user to confirm that they want to create new project without saving'
    }
});
const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleCloseFileMenuAndThen',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'restoreOptionMessage',
            'handleSaveButtonClick', //绑定点击保存button事件
            'handleBackClick', //绑定点击返回按钮事件
            'handleSubmitButtonClick', //绑定点击完成提交button事件
            //'handleThumbButtonClick', //测试缩略图提交
            'handleRestartButtonClick', //绑定点击重做button事件
        ]);
        this.state = {
            backUrl: undefined, // back参数
            hasCookie: false, // 是否携带了指定的cooke，从而决定是否加载 保存到电脑
            count : 60, // 保存次数,120次，两个小时
        }
    }

    // 缩略图提交
    /*
    handleThumbButtonClick(){
        console.log('thumbnail click');
        // 1.获取缩略图数据
        this.props.vm.postIOData('video', {forceTransparentPreview: true});
        this.props.vm.renderer.requestSnapshot(dataURI => {
            this.props.vm.postIOData('video', {forceTransparentPreview: false});
            // callback(dataURI);
            console.log(dataURI);
            // 将得到的dataURI转成Blob
            // const blob = dataURItoBlob(dataURI);
            let data = new FormData();
            // data.append('data', blob);
            data.append('data',dataURI);
            // console.log(blob);
            console.log(data);
            // 2.发起ajax 的 post 请求
            // let url = "https://scratch.mit.edu/internalapi/project/thumbnail/";
            let url ="http://www.daerhou.cn/Api/upload_img";
            fetch(url, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'image/png'
                // },
                body: data,
                // credentials: 'include'
            }).then(function (response) {
                console.log(response);
                return response.json();
            }).then(function (res) {
                console.log(res);
            }).catch(function (err) {
                console.log(err);
            });            
        }); 
        this.props.vm.renderer.draw();
    }
    */
    // 点击返回
    handleBackClick(e){
        window.location.href = this.state.backUrl;
    }
    // 保存到云服务器，不跳转
    saveToECS () {
        // const saveLink = document.createElement('a');
        // document.body.appendChild(saveLink);
        this.props.vm.saveProjectSb3().then(content => {
            // TODO user-friendly project name
            // File name: project-DATE-TIME
            let date = new Date();
            // const timestamp = `${date.toLocaleDateString()}-${date.toLocaleTimeString()}`;
            let timestamp = +new Date();
            // 获取文件名
            // let filename = 'untitled-project-' + timestamp + '.sb3';
            // 获取文件名，通过属性中的projectTitle获取
            let filename = this.props.projectTitle;
            // 获取查询字符串
            let qs = window.location.search ? window.location.search : '';
            // 配置url
            let url = 'https://www.daerhou.cn/Api/autosave'+qs;
            // 使用ajax方式提交数据到后台api
            let data = new FormData();
            data.append('filename', filename);
            data.append('data', content);
            fetch(url, {
                method: 'POST',
                body: data,
                credentials: 'include'
            }).then(function (response) {
                // console.log(response);
                return response.json();
            }).then(function (res) {
                // console.log(res);
                // alert('保存提交成功！');
                if (res.status == 1) {
                    alert('保存成功'); // 弹出即可
                } else {
                    alert(res.msg);
                }

            }).catch(function (err) {
                // console.log(err);
                alert('保存失败！');
            });
        });
    }
    // 保存到云服务器，跳转
    /*
    saveToECS2 () {
        // const saveLink = document.createElement('a');
        // document.body.appendChild(saveLink);

        this.props.vm.saveProjectSb3().then(content => {
            // TODO user-friendly project name
            // File name: project-DATE-TIME
            let date = new Date();
            // const timestamp = `${date.toLocaleDateString()}-${date.toLocaleTimeString()}`;
            let timestamp = +new Date();
            // 获取文件名
            // let filename = 'untitled-project-' + timestamp + '.sb3';
            // 获取文件名，通过属性中的projectTitle获取
            let filename = this.props.projectTitle;
            // 获取查询字符串
            let qs = window.location.search ? window.location.search : '';
            // 配置url
            let url = 'http://www.daerhou.cn/Api/save'+qs; //提交的url，会有打卡提示
            // 使用ajax方式提交数据到后台api
            let data = new FormData();
            data.append('filename', filename);
            data.append('data', content);
            console.log(data);
            fetch(url, {
                method: 'POST',
                body: data,
                credentials: 'include'
            }).then(function (response) {
                console.log(response);
                return response.json();
            }).then(function (res) {
                console.log(res);
                // alert('提交成功！');
                if (res.status == 1) {
                    alert('提交成功');
                    // 需要跳转
                    let redirect = querystring.parse(qs);
                    if (redirect.url) {
                        window.location.href = redirect.url;
                    }
                } else {
                    alert(res.msg);
                }

            }).catch(function (err) {
                console.log(err);
                alert('提交失败！');
            });
        });
    }
    */
    saveToECS2 () {
        // const saveLink = document.createElement('a');
        // document.body.appendChild(saveLink);
        this.props.vm.saveProjectSb3().then(content => {
            // 1.获取缩略图数据
            this.props.vm.postIOData('video', {forceTransparentPreview: true});
            this.props.vm.renderer.requestSnapshot(dataURI => {
                this.props.vm.postIOData('video', {forceTransparentPreview: false});
                // callback(dataURI);
                console.log(dataURI); //dataURI就是缩略图
                // 获取文件名，通过属性中的projectTitle获取
                let filename = this.props.projectTitle;
                // 获取查询字符串
                let qs = window.location.search ? window.location.search : '';
                // 配置url
                let url = 'https://www.daerhou.cn/Api/save'+qs; //提交的url，会有打卡提示
                // 使用ajax方式提交数据到后台api
                let data = new FormData();
                data.append('filename', filename); // 文件名
                data.append('data', content); // sb3文件
                data.append('thumbnail',dataURI); // 缩略图
                console.log(data);
                fetch(url, {
                    method: 'POST',
                    body: data,
                    credentials: 'include'
                }).then(function (response) {
                    // console.log(response);
                    return response.json();
                }).then(function (res) {
                    console.log(res);
                    // alert('提交成功！');
                    if (res.status == 1) {
                        alert('提交成功');
                        // 需要跳转
                        let redirect = querystring.parse(qs);
                        if (redirect.url) {
                            window.location.href = redirect.url;
                        }
                    } else {
                        alert(res.msg);
                    }
                }).catch(function (err) {
                    // console.log(err);
                    alert('提交失败！');
                });
            }); 
            this.props.vm.renderer.draw();
        });
    }

    // 保存到云服务器，ajax定时请求
    saveToECS3 () {
        // const saveLink = document.createElement('a');
        // document.body.appendChild(saveLink);

        this.props.vm.saveProjectSb3().then(content => {
            // TODO user-friendly project name
            // File name: project-DATE-TIME
            let date = new Date();
            // const timestamp = `${date.toLocaleDateString()}-${date.toLocaleTimeString()}`;
            let timestamp = +new Date();
            // 获取文件名
            // let filename = 'untitled-project-' + timestamp + '.sb3';
            // 获取文件名，通过属性中的projectTitle获取
            let filename = this.props.projectTitle;
            // 获取查询字符串
            let qs = window.location.search ? window.location.search : '';
            // 配置url
            let url = 'https://www.daerhou.cn/Api/autosave'+qs;
            // 使用ajax方式提交数据到后台api
            let data = new FormData();
            data.append('filename', filename);
            data.append('data', content);
            fetch(url, {
                method: 'POST',
                body: data,
                credentials: 'include'
            }).then(function (response) {
                console.log(response);
                return response.json();
            }).then(function (res) {
                console.log(res);
                // alert('保存提交成功！');
                if (res.status == 1) {
                    // alert('保存提交成功'); // 弹出即可
                } else {
                    // alert(res.msg);
                }

            }).catch(function (err) {
                console.log(err);
                // alert('保存提交失败！');
            });
        });
    }
    // 保存功能
    handleSaveButtonClick(e){
        this.saveToECS();
    }
    // 完成提交功能
    handleSubmitButtonClick(e){
        this.saveToECS2();
    }
    // 重做功能
    handleRestartButtonClick(e){
        // get提交，增加参数restart=1
        // 获取原先参数
        if (confirm("确定要重做吗？")) {
            let qsObj = querystring.parse(window.location.search);
            // 增加参数
            qsObj.restart = 1;
            // 得到新的查询字符串
            let qs = querystring.stringify(qsObj);
            let url = 'https://scratch.daerhou.cn/?'+qs;
            // 发起请求
            window.location.href = url;
        }
    }
    handleClickNew () {
        let readyToReplaceProject = true;
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        if (this.props.projectChanged && !this.props.canCreateNew) {
            readyToReplaceProject = confirm( // eslint-disable-line no-alert
                this.props.intl.formatMessage(messages.confirmNav)
            );
        }
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        }
        this.props.onRequestCloseFile();
    }
    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.canSave) { // save before transitioning to project page
            this.props.autoUpdateProject();
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleCloseFileMenuAndThen (fn) {
        return () => {
            this.props.onRequestCloseFile();
            fn();
        };
    }
    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    componentDidMount() {
        // 获取查询字符串
        let qs = window.location.search;
        let qsobj = querystring.parse(qs);
        // console.log(qs);
        // console.log(qsobj);
        // 判断back参数
        if (qsobj.back) {
            // 说明有back参数，
            this.setState({
                backUrl: qsobj.back
            });
        } 
        //获取cookie
        const cookie = cookies.get('onethink_home_scratch_teacher');
        // console.log(cookie);
        if(cookie) {
            this.setState({
                hasCookie : true
            });
        }
        // 增加定时器，定时发送ajax
        this.timerID = setInterval(
            () => {
                this.saveToECS3()
                this.setState({
                    count : this.state.count - 1
                });
                if (this.state.count == 0) {
                    clearInterval(this.timerID);
                }
            },
            120000
          );
    }
    // 删除定时器
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        {this.state.backUrl ? 
                            <Button
                                iconSrc={backIcon}
                                onClick={this.handleBackClick}
                            >
                                <FormattedMessage
                                    defaultMessage="Back"
                                    description="Back button in modal"
                                    id="gui.modal.back"
                                />
                            </Button> :
                            <div className={classNames(styles.menuBarItem)}>
                                <img
                                    alt="Scratch"
                                    className={classNames(styles.scratchLogo, {
                                        [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                    })}
                                    draggable={false}
                                    src={scratchLogo}
                                    onClick={this.props.onClickLogo}
                                />
                            </div>
                        }
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}
                        >
                            <div>
                                <img
                                    className={styles.languageIcon}
                                    src={languageIcon}
                                />
                                <img
                                    className={styles.languageCaret}
                                    src={dropdownCaret}
                                />
                            </div>
                            <LanguageSelector label={this.props.intl.formatMessage(ariaMessages.language)} />
                        </div>
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.fileMenuOpen
                            })}
                            onMouseUp={this.props.onClickFile}
                        >
                            <FormattedMessage
                                defaultMessage="File"
                                description="Text for file dropdown menu"
                                id="gui.menuBar.file"
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.fileMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseFile}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                    <MenuSection>
                                        {this.props.canSave ? (
                                            <MenuItem onClick={this.handleClickSave}>
                                                {saveNowMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canCreateCopy ? (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canRemix ? (
                                            <MenuItem onClick={this.handleClickRemix}>
                                                {remixMessage}
                                            </MenuItem>
                                        ) : []}
                                    </MenuSection>
                                )}
                                <MenuSection>
                                    <SBFileUploader onUpdateProjectTitle={this.props.onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Load from your computer"
                                                    description={
                                                        'Menu bar item for uploading a project from your computer'
                                                    }
                                                    id="gui.menuBar.uploadFromComputer"
                                                />
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                    {this.state.hasCookie ?
                                        <SB3Downloader>{(className, downloadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={this.handleCloseFileMenuAndThen(downloadProject)}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Save to your computer"
                                                    description="Menu bar item for downloading a project to your computer"
                                                    id="gui.menuBar.downloadToComputer"
                                                />
                                            </MenuItem>
                                        )}</SB3Downloader> : []
                                    }
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.editMenuOpen
                            })}
                            onMouseUp={this.props.onClickEdit}
                        >
                            <div className={classNames(styles.editMenu)}>
                                <FormattedMessage
                                    defaultMessage="Edit"
                                    description="Text for edit dropdown menu"
                                    id="gui.menuBar.edit"
                                />
                            </div>
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.editMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseEdit}
                            >
                                <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                    <MenuItem
                                        className={classNames({[styles.disabled]: !restorable})}
                                        onClick={this.handleRestoreOption(handleRestore)}
                                    >
                                        {this.restoreOptionMessage(deletedItem)}
                                    </MenuItem>
                                )}</DeletionRestorer>
                                <MenuSection>
                                    <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                        <MenuItem onClick={toggleTurboMode}>
                                            {turboMode ? (
                                                <FormattedMessage
                                                    defaultMessage="Turn off Turbo Mode"
                                                    description="Menu bar item for turning off turbo mode"
                                                    id="gui.menuBar.turboModeOff"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    defaultMessage="Turn on Turbo Mode"
                                                    description="Menu bar item for turning on turbo mode"
                                                    id="gui.menuBar.turboModeOn"
                                                />
                                            )}
                                        </MenuItem>
                                    )}</TurboMode>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    </div>
                    <Divider className={classNames(styles.divider)} />
                    {/* 教程按钮
                    <div
                        aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                        className={classNames(styles.menuBarItem, styles.hoverable)}
                        onClick={this.props.onOpenTipLibrary}
                    >
                        <img
                            className={styles.helpIcon}
                            src={helpIcon}
                        />
                        <FormattedMessage {...ariaMessages.tutorials} />
                    </div>
                    <Divider className={classNames(styles.divider)} />
                    */}
                    {this.props.canEditTitle ? (
                        <div className={classNames(styles.menuBarItem, styles.growable)}>
                            <MenuBarItemTooltip
                                enable
                                id="title-field"
                            >
                                <ProjectTitleInput
                                    className={classNames(styles.titleFieldGrowable)}
                                    onUpdateProjectTitle={this.props.onUpdateProjectTitle}
                                />
                            </MenuBarItemTooltip>
                        </div>
                    ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null)}          

                    <Divider className={classNames(styles.divider)} />
                    {/* 临时保存按钮 暂时屏蔽*/}
                    {/* 
                    <div className={classNames(styles.menuBarItem)} onClick={this.handleSaveButtonClick}>
                        
                        <Button className={classNames(styles.shareButton,styles.buleButton)}>
                            <FormattedMessage
                                defaultMessage="save"
                                description="Label for project post and save"
                                id="gui.menuBar.postSave"
                            />
                        </Button>
                    </div>
                    */}
                    {/* 测试上传图片 */}
                    {/*
                    <div className={classNames(styles.menuBarItem)} onClick={this.handleThumbButtonClick}>
                        <Button className={classNames(styles.shareButton,styles.buleButton)}>
                            <FormattedMessage
                                defaultMessage="缩略图"
                                description="Label for project post and save"
                                id="gui.menuBar.postThumb"
                            />
                        </Button>
                    </div>
                    */}
                    <div className={classNames(styles.menuBarItem)} onClick={this.handleSubmitButtonClick}>
                        {/*保存提交*/}
                        <Button className={classNames(styles.shareButton,styles.yellowButton)}>
                            <FormattedMessage
                                defaultMessage="complete"
                                description="Label for project post and save2"
                                id="gui.menuBar.postSave2"
                            />
                        </Button>
                    </div>
                    <div className={classNames(styles.menuBarItem)} onClick={this.handleRestartButtonClick}>
                        {/*重做*/}
                        <Button className={classNames(styles.shareButton,styles.blueButton)}>
                            <FormattedMessage
                                defaultMessage="restart"
                                description="Label for project post and restart"
                                id="gui.menuBar.restart"
                            />
                        </Button>
                    </div>
                </div>
                
                {/* show the proper UI in the account menu, given whether the user is
                logged in, and whether a session is available to log in with */}
              </Box>
        );
    }
}

MenuBar.propTypes = {
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    loginMenuOpen: PropTypes.bool,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    projectChanged: PropTypes.bool,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    showComingSoon: PropTypes.bool,
    username: PropTypes.string
};

MenuBar.defaultProps = {
    onShare: () => {}
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        loginMenuOpen: loginMenuOpen(state),
        projectChanged: state.scratchGui.projectChanged,
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        vm: state.scratchGui.vm // 增加vm
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar));
